
import { VImg } from "vuetify/lib/components"

export default {
    name: "ImageBlok",
    components: { VImg },
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    computed: {
        imageSrc() {
            return this.blok.image.filename
        },
        style() {
            return {
                margin: `${this.blok.padding ?? 0}px`,
            }
        },
    },
}
