
import { SearchResult } from "@/components/search"
import sanitizeText from "@/utils/sanitize-text"
import { getJobBySlug } from "@/services/job.service"
import { getPost } from "@/services/post.service"

export default {
    name: "ManualListBlok",
    components: { SearchResult },
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    async fetch() {
        if (!this.blok.items) return
        const items = this.blok.items.split(",").map((item) => item.trim())
        const promises = items.map(async (item) => {
            switch (this.blok.type) {
                case "traineeship":
                case "apprenticeship":
                    return this.$api.run(getJobBySlug, item).request
                case "post":
                    return this.$api.run(getPost, item).request
                default:
                    return null
            }
        })
        const resolved = await Promise.allSettled(promises)
        this.items = resolved
            .filter((item) => item.value)
            .map((item) => item.value)
    },
    data: () => ({
        items: [],
    }),
    computed: {
        category() {
            switch (this.blok.type) {
                case "post":
                    return "Neuigkeiten"
                case "apprenticeship":
                    return "Ausbildung"
                case "business":
                    return "Unternehmen"
                case "traineeship":
                    return "Praktikum"
                default:
                    return "Ausbildung"
            }
        },
        contentType() {
            if (["apprenticeship", "traineeship"].includes(this.blok.type))
                return "job"
            if (this.blok.type === "region-post") return "post"
            if (this.blok.type === "business_site") return "business"
            return this.blok.type
        },
    },
    methods: {
        parseText(s) {
            if (!s) return
            return sanitizeText(s).replace(/\*([^*]+?)\*/g, "<b>$1</b>")
        },
    },
}
