import { render, staticRenderFns } from "./JobCard.vue?vue&type=template&id=73a79914&scoped=true&"
import script from "./JobCard.vue?vue&type=script&lang=js&"
export * from "./JobCard.vue?vue&type=script&lang=js&"
import style0 from "./JobCard.vue?vue&type=style&index=0&id=73a79914&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73a79914",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Picture: require('/builds/oabat/einblick/web/src/components/oabatify/Picture.vue').default,Icon: require('/builds/oabat/einblick/web/src/components/oabatify/Icon.vue').default,Card: require('/builds/oabat/einblick/web/src/components/oabatify/Card.vue').default})
