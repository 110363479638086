
import { BusinessResponseTime } from "@/components/business"
import { ContactPersonAvatar } from "@/components/business/contactperson"
import { speechbubble } from "@/assets/icons"

export default {
    name: "ContactPersonSection",
    components: {
        ContactPersonAvatar,
        BusinessResponseTime,
    },
    props: {
        business: {
            type: Object,
            required: true,
        },
        chat: {
            type: Boolean,
            default: false,
        },
        apply: {
            type: Boolean,
            default: false,
        },
    },
    icons: {
        speechbubble,
    },
    emits: ["chat", "apply"],
    computed: {
        contactPerson() {
            return this.business?.public_contact_person
        },
        displayName() {
            if (this.contactPerson?.firstname || this.contactPerson?.lastname) {
                return `${this.contactPerson.firstname ?? ""} ${
                    this.contactPerson.lastname ?? ""
                }`
            }
            return this.business.name
        },
        displayGender() {
            return this.contactPerson?.gender
        },
    },
}
