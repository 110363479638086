import { routeToBusinessMain, routeToBusinessSite, routeToJob } from "@/helpers/routingEinBlick"
import { getRouter } from "@/helpers/context"
import { getFullBaseURL } from "@/helpers"

export function getBreadcrumb(items) {
    const itemListElement = []
    items.forEach((item, position) => {
        if (item.identifier === "business") {
            itemListElement.push(getBusinessBreadcrumbListElement(item.business, position))
        } else if (item.identifier === "business_site") {
            itemListElement.push(getBusinessSiteBreadcrumbListElement(item.business_site, position))
        } else if (item.identifier === "job") {
            itemListElement.push(getJobBreadcrumbListElement(item.job, position))
        }
    })
    return {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        itemListElement,
    }
}

function getBusinessBreadcrumbListElement(business, position) {
    return {
        "@type": "ListItem",
        position: position,
        name: business.name,
        item: getAbsoluteURL(routeToBusinessMain()),
    }
}

function getBusinessSiteBreadcrumbListElement(businessSite, position) {
    return {
        "@type": "ListItem",
        position: position,
        name: businessSite.address.city,
        item: getAbsoluteURL(routeToBusinessSite(businessSite)),
    }
}

function getBreadcrumbJobName(job) {
    if (job.type_id === 1) { return "Ausbildung " + job.name }
    else if (job.type_id === 2) { return "Praktikum " + job.name }
    else if (job.type_id === 3) { return job.name }
}

function getJobBreadcrumbListElement(job, position) {
    return {
        "@type": "ListItem",
        position: position,
        name: getBreadcrumbJobName(job),
        item: getAbsoluteURL(routeToJob(job)),
    }
}

function getAbsoluteURL(routeObject) {
    return getFullBaseURL() + getRouter().resolve(routeObject).route.path
}