
import { LocationModal } from "@/components/businesssite"

import { mdiMapMarker, mdiChevronDown } from "@mdi/js"

export default {
    name: "BusinessSiteSelect",
    components: {
        LocationModal,
    },
    props: {
        /** Business object */
        business: {
            type: Object,
            required: true,
        },
        /** Currently selected businessSite */
        current: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        showLocationModal: false,
    }),
    computed: {
        businessSites() {
            return this.business.business_sites
        },
        currentLocation() {
            if (this.businessSites.length === 1)
                return this.business.primary_address.city
            return this.current?.address.city || "Standort wählen"
        },
        hasMultiple() {
            return this.businessSites.length > 1
        },
    },
    icons: {
        mdiMapMarker,
        mdiChevronDown,
    },
}
