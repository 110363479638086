
import { mdiChevronDown } from "@mdi/js"

export default {
    name: "PageBlok",
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        showScrollHint: false,
        scrollTimer: null,
    }),
    icons: {
        mdiChevronDown,
    },
    computed: {
        style() {
            const style = {}
            if (this.blok.customStyle) {
                for (const entry of this.blok.customStyle.split(";")) {
                    const [key, value, ...rest] = entry.split(":")
                    if (rest?.length > 0) {
                        console.warn(
                            "Too many values in CSS string:",
                            entry,
                            rest
                        )
                    }
                    style[key] = value
                }
            }
            return style
        },
        background() {
            if (!this.blok.backgroundColor) return {}
            return { backgroundColor: this.blok.backgroundColor }
        },
    },
    mounted() {
        if (this.blok.scrollHint !== "") {
            window.addEventListener("scroll", this.handleScroll)
            const delay = Number(this.blok.scrollHint)
            if (delay <= 0) {
                this.showScrollHint = true
            } else {
                this.scrollTimer = setTimeout(() => {
                    this.showScrollHint = true
                }, delay * 1000)
            }
        }
    },
    methods: {
        scrollToNext() {
            window.scrollTo({
                top: window.innerHeight,
                behavior: "smooth",
            })
        },
        handleScroll() {
            clearTimeout(this.scrollTimer)
            this.showScrollHint = false
            /** Execute only once */
            window.removeEventListener("scroll", this.handleScroll)
        },
    },
}
