import { getImage } from "@/helpers/image"
import { getApp } from "@/helpers/context"

export { getBreadcrumb } from "@/helpers/headBreadcrumb"

/** Creates head object using builder  */
export function createHead() {
    return {
        result: { meta: [], script: [] },
        setTitle(title) {
            if (!title) return this
            this.result.title = title
            this.result.meta.push({
                hid: "og:title",
                name: "og:title",
                content: title,
            })
            return this
        },
        setDescription(desc) {
            if (!desc) return this
            this.result.meta.push(
                {
                    hid: "description",
                    name: "description",
                    content: desc,
                },
                {
                    hid: "og:description",
                    name: "og:description",
                    content: desc,
                }
            )
            return this
        },
        setImage(image) {
            if (!image) return this
            const { type, src } = image
            this.result.meta.push(
                {
                    hid: "og:image",
                    name: "og:image",
                    content: src,
                },
                {
                    hid: "og:image:type",
                    name: "og:image:type",
                    content: type,
                }
            )
            return this
        },
        addScript(script, type = "application/ld+json") {
            if (!script) return this
            this.result["__dangerouslyDisableSanitizers"] = ["script"]
            this.result.script.push({
                innerHTML: JSON.stringify(script),
                type,
            })
            return this
        },
        /** Returns final head object */
        build() {
            return this.result
        },
    }
}

export function createDefaultHead(businessSite = null) {
    const business = getApp().$businessProvider?.business
    let description = `${business.name} gibt dir Einblick in die spannenden Berufe, die es bei uns gibt.`
    const siteLocation = businessSite?.address?.city
    const jobs = businessSite?.published_jobs || business.jobs || []
    if (!businessSite) {
        if (jobs.length === 0) {
            description += ` Wir haben derzeit keine offenen Berufe.`
        } else {
            const isMainSite = business.business_sites.length === 1
            if (isMainSite) {
                description += ` Unsere Berufe in ${business.primary_address.city}: \n- `
                description += jobs.map((job) => job.name).join(",\n- ")
            } else {
                description += ` Unsere Berufe: \n- `
                description += jobs
                    .map(
                        (job) =>
                            `${job.name} (${job.business_site.address.city})`
                    )
                    .join(",\n- ")
            }
        }
    } else {
        if (jobs.length === 0) {
            description += ` Wir haben derzeit keine offenen Berufe in ${siteLocation}.`
        } else {
            description += ` Unsere Berufe in ${siteLocation}: \n- `
            description += jobs.map((job) => job.name).join(",\n- ")
        }
    }

    let title = `Berufe bei ${business.name}`
    if (siteLocation) title += ` in ${siteLocation}`

    return createHead()
        .setTitle(title)
        .setDescription(description)
        .setImage(getImage(business.logo, 2, "jpg").src)
}
