
import { JobCardSkeleton, JobCard } from "@/components/job"
import { LazyViewportRender } from "@/components/base"
import { hashString } from "@/helpers/utils"

export default {
    name: "CardGridDynamic",
    components: {
        LazyViewportRender,
        JobCardSkeleton,
        JobCard
    },
    props: {
        target: {
            type: String,
            default: undefined,
            validate: (value) => {
                return ["_blank", "_self", "_parent", "_top"].includes(value)
            },
        },
        embedMode: {
            type: Boolean,
            default: false,
        },
        /** list of items  */
        items: {
            type: Array,
            default: () => [],
        },
        /**
         * item component type
         * @values job, business
         * @default job
         */
        itemType: {
            type: String,
            default: "job",
            validator: (val) => ["job", "business", "post"].includes(val),
        },
        /** item's height */
        height: {
            type: Number,
            default: 450,
            validator: (val) => val >= 0,
        },
        /** item's width */
        width: {
            type: Number,
            default: 350,
            validator: (val) => val >= 0,
        },
        /** is item's width fixed (cannot shrink) */
        fixedWidth: {
            type: Boolean,
            default: false,
        },
        /** grid gap between rows and columns */
        gridGap: {
            type: Number,
            default: 20,
            validator: (val) => val >= 0,
        },
        /** show skeleton loader */
        loading: {
            type: Boolean,
            default: false,
        },
        /** number of skeleton loader cards */
        loadingItems: {
            type: Number,
            default: 12,
        },
        /** Sets intesection handler for fetching more elements */
        intersectionHandler: {
            type: Function,
            default: undefined,
        },
        /** max number of rows */
        rows: {
            type: Number,
            default: undefined,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        totalNumberOfItems: {
            type: Number,
            default: undefined,
        },
        fluidConfig: {
            type: Object,
            default: null,
        },
    },
    emits: ["fetch"],
    fetch() {
        this.randomNum = Math.random()
    },
    data: () => ({
        numberOfCols: 0, // for onResize items fetching
        randomNum: 0, // ssr compatible random number for grid element id
    }),
    computed: {
      numberOfBufferCards() {
          if (!this.totalNumberOfItems || !this.showSkeletonIntersector)
              return 0
          return Math.min(
              Math.max(this.totalNumberOfItems - this.items?.length - 1, 0),
              12
          )
      },
      isAllVisible() {
          return this.items.length === this.visibleItems.length
      },
      visibleItems() {
          if (this.loading) return this.loadingItems
          if (this.numberOfCols === 0) return this.items // Return full list on SSR for SEO
          if (!this.rows) return this.items
          return this.items.slice(0, this.rows * this.numberOfCols)
      },
      /** styles of the wrapper component */
      wrapperStyles() {
          return {
              gap: `${this.gridGap}px`,
          }
      },
      fluidGridStyles() {
          if (!this.fluidConfig) return []
          const maxColumns = Math.min(this.items.length, this.fluidConfig.maxCols)
          const realColumns = Math.min(this.items.length, maxColumns)
          return [
            `--maxColumns: ${maxColumns};`,
            `--realColumns: ${realColumns};`,
            `--minWidth: ${this.fluidConfig.minColWidth}px;`,
            `--maxWidth: ${this.fluidConfig.maxColWidth}px;`,
            `--gridGap: ${this.gridGap}px;`,
          ]
      },

      showIntersector() {
            if (this.loading) return false
            if (!this.intersectionHandler) return false
            if (this.totalNumberOfItems) return false
            if (!this.rows) return true
            if (!this.numberOfCols) return true
            return this.items.length < this.rows * this.numberOfCols
        },
        showSkeletonIntersector() {
            if (!this.totalNumberOfItems) return false
            if (
                !!this.totalNumberOfItems &&
                this.totalNumberOfItems - this.items?.length <= 0
            )
                return false
            return true
        },
        isFluidGrid() {
            return (
              this.fluidConfig &&
              this.fluidConfig.enabled &&
              this.fluidConfig.maxCols > 0 &&
              this.fluidConfig.minColWidth > 0 &&
              this.fluidConfig.maxColWidth > this.fluidConfig.minColWidth
            )
        },
        id() {
            if (!this.isFluidGrid) return null
            return `fluid-grid-${hashString(this.fluidGridStyles.join("") + this.randomNum)}`
        },
    },
    methods: {
        onResize() {
            if (!this.rows) return
            const container = this.$refs.grid
            if (!container) return
            this.numberOfCols = getComputedStyle(container)
                .getPropertyValue("grid-template-columns")
                .split(" ").length
            if (this.rows * this.numberOfCols > this.items.length) {
                this.$emit(
                    "fetch",
                    this.rows * this.numberOfCols -
                        this.items.length /* Number of missing entries */
                )
            }
        },
        /** v-bind to the corresponding component */
        getAttributes(item) {
            if (item.boundData) return item.boundData
            return {
                job: item,
                fixedWidth: this.fixedWidth,
                enableTouchSupport: true,
                showTimestamp: true,
            }
        },
    },
    head() {
        if (!this.isFluidGrid) return
        const style = `#${this.id} {${this.fluidGridStyles.join("")}}`
        return {
          style: [{ hid: this.id, cssText: style, type: "text/css" }],
        }
    },
}
