
import { VDatePicker } from "vuetify/lib/components"
export default {
    name: "DatePicker",
    components: { VDatePicker },
    model: {
        prop: "dates",
        event: "update",
    },
    props: {
        /** String for single-day selection
         * Array if "range" is set
         */
        dates: {
            type: [Array, String],
            default: () => [],
        },
        /** How many years into the future can be selected */
        futureDistance: {
            type: Number,
            default: 1,
            validate: (v) => v > 0,
        },
        /** Enables range selection
         * requires v-model as array [from,to] */
        range: {
            type: Boolean,
            default: false,
        },
        minStartDate: {
            type: String,
            default: null,
        },
        maxEndDate: {
            type: String,
            default: null,
        },
    },
    computed: {
        startDate() {
            return this.minStartDate
                ? this.minStartDate
                : new Date().toISOString().substring(0, 10)
        },
        endDate() {
            if (this.maxEndDate) {
                return this.maxEndDate
            } else {
                const date = ((d) =>
                    d.setFullYear(d.getFullYear() + this.futureDistance))(
                    new Date()
                )
                return new Date(date).toISOString().substring(0, 10)
            }
        },
        computedDates: {
            get() {
                return this.dates
            },
            set(arr) {
                if (this.range) {
                    if (this.dates.includes(arr[1])) return
                    if (arr.length === 0) this.$emit("update", [])
                    else if (arr.length === 1) this.$emit("update", arr)
                    else this.$emit("update", this.sortDates(arr))
                } else {
                    this.$emit("update", arr)
                }
            },
        },
    },
    methods: {
        sortDates(dates) {
            return dates.sort((d1, d2) => new Date(d1) - new Date(d2))
        },
    },
}
