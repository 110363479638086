
import { routeToJob } from "@/helpers/routingEinBlick"
import intersectionObserver from "@/mixins/intersectionObserver"

import { location } from "@/assets/icons"
import { BusinessLogo } from "@/components/business"
import { ResponsiveVimeo } from "@/components/base/video"

import { genGradientArray } from "@/helpers/themesHelper"
import { getFullBaseURL } from "@/helpers"
import media from "@/mixins/media"
import campaign from "@/mixins/campaign"

export default {
    name: "JobCard",
    components: {
        BusinessLogo,
        ResponsiveVimeo,
    },
    mixins: [intersectionObserver, media, campaign],
    props: {
        job: {
            type: Object,
            required: true,
        },
        target: {
            type: String,
            default: undefined,
            validate: (value) => {
                return ["_blank", "_self", "_parent", "_top"].includes(value)
            },
        },
        /** Autoplays video */
        autoplay: {
            type: Boolean,
            default: false,
        },
        /** Shows business logo */
        logo: {
            type: Boolean,
            default: false,
        },
    },
    icons: {
        location,
    },
    computed: {
        city() {
            return this.job.business_site?.address?.city || "In der Nähe"
        },
        backgroundImage() {
            if (this.job.image) return this.job.image
            const businessImages = this.job.business.main_images
            if (Array.isArray(businessImages) && businessImages.length > 0) {
                const randomIndex = Math.floor(
                    Math.random() * businessImages.length
                )
                return businessImages[randomIndex]
            }
            return this.job.image
        },
        /** TODO: Use theme css-variables */
        minimalBackgroundStyles() {
            const gradientColors = genGradientArray(
                this.job.business.logo?.color_palette
            )
            let gradient = "linear-gradient(120deg"
            gradientColors.forEach((color, index) => {
                const colorsLen = gradientColors.length
                const percentage1 = Math.max(0, (index / colorsLen) * 100 - 2)
                const percentage2 = ((index + 1) / colorsLen) * 100
                gradient += `, ${color} ${percentage1}% ${percentage2}%`
            })
            gradient += ")"
            return { background: gradient }
        },
        jobRoute() {
            return routeToJob(this.job, this.campaign?.toQuery())
        },
    },
    beforeDestroy() {
        const cardEl = this.$refs.card?.$el
        if (!cardEl) return
        // PC events
        cardEl.removeEventListener("mouseenter", this.play, { passive: true })
        cardEl.removeEventListener("mouseleave", this.pause, { passive: true })
        cardEl.removeEventListener("mousemove", this.play, { passive: true })
        // Tablet events
        if (this.$ua.isFromTablet() || this.$ua.isFromPc()) {
            cardEl.removeEventListener("touchstart", this.play)
            document.removeEventListener("touchstart", this.onTouchOutside)
        }
    },
    methods: {
        onIntersect() {
            this.$oabalytics.trackImpression("job", this.job.id)
        },
        onClick() {
            this.$oabalytics.trackEvent(
                "job_card_click",
                {
                    id: this.job.id,
                },
                "job",
                this.job.id
            )
            if (this.target === "_top") {
                window.top.location.href =
                    getFullBaseURL() + this.$router.resolve(this.jobRoute).href
            }
        },
        onVimeoReady() {
            const cardEl = this.$refs.card.$el
            if (this.$ua.isFromSmartphone())
                this.observeIntersection(cardEl, this.play, this.pause, {
                    threshold: 0.75,
                })
            // PC events
            cardEl.addEventListener("mouseenter", this.play, { passive: true })
            cardEl.addEventListener("mouseleave", this.pause, { passive: true })
            cardEl.addEventListener("mousemove", this.play, {
                once: true,
                passive: true,
            })
            // Tablet events
            if (this.$ua.isFromTablet() || this.$ua.isFromPc()) {
                cardEl.addEventListener("touchstart", this.play, {
                    passive: true,
                })
                document.addEventListener("touchstart", this.onTouchOutside, {
                    passive: true,
                })
            }
        },
        play() {
            this.$refs.player?.playVideo()
        },
        pause() {
            this.$refs.player?.pauseVideo()
        },
        onTouchOutside(e) {
            if (e.target !== this.$refs.card.$el) this.pause()
        },
    },
}
