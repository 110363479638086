
export default {
    name: "BlockBlok",
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isDiagonal(direction, breakpoint) {
            const blokValue = this.blok.diagonalBorders
            const key = `${direction}-${breakpoint}`
            return blokValue?.includes(key) ?? false
        },
        diagonalClasses() {
            if (!this.blok.diagonalBorders) return []
            const borders = this.blok.diagonalBorders.map((border) => {
                return `diagonal--${border}`
            })
            return [...borders, "diagonal"]
        },
        classes() {
            return [
                ...this.diagonalClasses,
                {
                    "storyblok-fullwidth": this.useInnerSpacing,
                    "storyblok-viewheight": !!this.blok.viewheight,
                },
            ]
        },
        innerClasses() {
            return [
                this.blok.viewheight,
                {
                    "storyblok-spacing": this.useInnerSpacing,
                },
            ]
        },
        useInnerSpacing() {
            const hasBackground =
                this.blok.background || this.blok.backgroundImage
            return hasBackground && !this.blok.fullwidth
        },
        style() {
            const style = {}
            if (this.blok.background) {
                style.background = this.blok.background
            }
            if (this.blok.backgroundImage) {
                style.backgroundImage = `url(${this.blok.backgroundImage.filename})`
            }
            if (this.blok.minHeight) {
                style.minHeight = this.blok.minHeight
            }
            style.backgroundPosition = this.blok.backgroundImagePosition
            style.backgroundSize = this.blok.backgroundImageFit
            return style
        },
    },
}
