
import themeable from "@/mixins/themeable"

export default {
    name: "TraineeshipDatePicker",
    mixins: [themeable],
    props: {
        /** Hides chat buttons */
        hideChat: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["interested"],
    data: () => ({
        dates: [],
    }),
    computed: {
        computedDates: {
            get() {
                return this.dates
            },
            set(arr) {
                if (this.dates.includes(arr[1])) return
                if (arr.length === 0) this.dates = []
                else if (arr.length === 1) this.dates = arr
                else this.dates = this.sortDates(arr)
            },
        },
        startDate() {
            return new Date().toISOString().substring(0, 10)
        },
        endDate() {
            const date = ((d) => d.setFullYear(d.getFullYear() + 1))(new Date())
            return new Date(date).toISOString().substring(0, 10)
        },
        controlsColor() {
            return this.themeIsTextDark(this.theme.backgroundPrimary)
                ? "grey-6"
                : "grey-2"
        },
        isTextDark() {
            return this.themeIsTextDark(this.theme.backgroundPrimary)
        },
    },
    methods: {
        onInterested(type) {
            this.$oabalytics.trackEvent("interested_open")
            this.$survey?.startTimer()
            if (type === "flexible") this.$emit("interested")
            else this.$emit("interested", this.dates)
        },
    },
}
