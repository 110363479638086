import { render, staticRenderFns } from "./Article.vue?vue&type=template&id=3202c04c&scoped=true&"
import script from "./Article.vue?vue&type=script&lang=js&"
export * from "./Article.vue?vue&type=script&lang=js&"
import style0 from "./Article.vue?vue&type=style&index=0&id=3202c04c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3202c04c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OImg: require('/builds/oabat/einblick/web/src/components/oabatify/OImg.vue').default,Icon: require('/builds/oabat/einblick/web/src/components/oabatify/Icon.vue').default,Spacer: require('/builds/oabat/einblick/web/src/components/oabatify/Spacer.vue').default,Button: require('/builds/oabat/einblick/web/src/components/oabatify/Button.vue').default,Card: require('/builds/oabat/einblick/web/src/components/oabatify/Card.vue').default})
