export default {
    methods: {
        isVideo(media) {
            if (!media) return false
            return (
                media.vimeo_id !== undefined ||
                !!media.isVideo ||
                !!media.type?.startsWith("video")
            )
        },
        isVideoDisplayable(media) {
            if (!this.isVideo(media)) return false
            return media.is_rendered || !!media.local
        },
        isImage(media) {
            if (!media) return false
            return (
                !this.isVideo(media) &&
                (!!media.type?.startsWith("image") ||
                    !!media.file_path?.includes("images"))
            )
        },
        getMediaKey(media, prefix = "") {
            const mediaType = this.isVideo(media) ? "video" : "image"
            const id = media.uid || media.id
            return `${prefix}${mediaType}-${id}`
        },
    },
}
