import { render, staticRenderFns } from "./BusinessLogo.vue?vue&type=template&id=4c987f1e&"
import script from "./BusinessLogo.vue?vue&type=script&lang=js&"
export * from "./BusinessLogo.vue?vue&type=script&lang=js&"
import style0 from "./BusinessLogo.vue?vue&type=style&index=0&id=4c987f1e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Picture: require('/builds/oabat/einblick/web/src/components/oabatify/Picture.vue').default,Icon: require('/builds/oabat/einblick/web/src/components/oabatify/Icon.vue').default})
