
import {CardGridDynamic} from "@/components/search"

import { Search } from "@/models/search"

export default {
    name: "SearchResult",
    components: {
        CardGridDynamic,
    },
    props: {
        target: {
            type: String,
            default: undefined,
            validate: (value) => {
                return ["_blank", "_self", "_parent", "_top"].includes(value)
            },
        },
        /** search object
         * String for legacy search (search category),
         */
        search: {
            type: [Search],
            validator: () => true,
        },
        /**
         * type of search
         * @values job, business
         * @default job
         */
        contentType: {
            type: String,
            default: "job",
            validator: (val) => ["job", "business", "post"].includes(val),
        },
        /** number of maximum rows */
        rows: {
            type: Number,
            default: undefined,
        },
        /** display loading state of the search (only initial loading) */
        loading: {
            type: Boolean,
            default: false,
        },
        /** sets custom result items list, disabled fetching */
        items: {
            type: Array,
            default: undefined,
        },
        customItems: {
            type: Array,
            default: undefined,
        },
        /** dark mode of the job cards */
        dark: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        isSearching: false,
        lists: [],
    }),
    computed: {
        searchItems() {
            if (this.items) return this.items
            if (!this.search) return []
            const items = [...this.search.data]
            if (this.customItems?.length > 0) {
                const INLINE_ITEM_SPACING = 6
                let inlineItemIndex = 2
                for (let i = 0; i < this.customItems.length; i++) {
                    items.splice(inlineItemIndex, 0, this.customItems[i])
                    inlineItemIndex += INLINE_ITEM_SPACING
                }
            }
            return items
        },
        isSearchAvailable() {
            if (this.isLegacy) return true
            return !!this.search
        },
        totalNumberOfItems() {
            if (this.search?.meta?.total) {
                return this.search?.meta?.total + this.customItems?.length || 0
            }
            return null
        },
    },
    methods: {
        onIntersection(_1, _2, isIntersecting) {
            if (this.items) return
            if (!this.isSearchAvailable) return
            if (isIntersecting) {
                return this.performSearch()
            }
        },
        async performSearch() {
            if (!this.isSearchAvailable) return
            this.isSearching = true
            await this.search.execute()
            this.isSearching = false
        },
        onFetch() {
            if (this.items) return
            this.performSearch()
        },
    },
}
