import { render, staticRenderFns } from "./JobApprenticeshipDatePicker.vue?vue&type=template&id=080ddadc&scoped=true&"
import script from "./JobApprenticeshipDatePicker.vue?vue&type=script&lang=js&"
export * from "./JobApprenticeshipDatePicker.vue?vue&type=script&lang=js&"
import style0 from "./JobApprenticeshipDatePicker.vue?vue&type=style&index=0&id=080ddadc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "080ddadc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/builds/oabat/einblick/web/src/components/oabatify/Button.vue').default,Card: require('/builds/oabat/einblick/web/src/components/oabatify/Card.vue').default})
