export const EVENT_APPLY_MODAL_ACTIVATOR_CLICK = 'apply_modal_activator_button'

export const EVENT_APPLY_MODAL_MESSAGE_BUTTON_CLICK = 'apply_modal_message_button'

export const EVENT_APPLY_MODAL_APPLY_BUTTON_CLICK = 'apply_modal_apply_button'

export const EVENT_APPLY_BUTTON_CLICK = 'apply_button'

export const EVENT_ADD_EMAIL = 'add_email'

export const EVENT_ADD_NAME = 'add_name'

export const EVENT_ADD_MESSAGE = 'add_message'

export const EVENT_MESSAGE_BUTTON_CLICK = 'message_button'

export const EVENT_APPLIED = 'applied'

export const EVENT_MESSAGE_SENT = 'message_sent'
