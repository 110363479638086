export function getLocationFromRoute(route) {
    const slug = route.params.locationSlug
    return getLocationFromSlug.call(this, slug)
}

export function getLocationFromSlug(slug) {
    const ZIP_REGEX = /zip-(.*)-([0-9]+)$/
    const DEFAULT_REGEX = /-([0-9]+)$/

    if (ZIP_REGEX.test(slug)) {
        return this.get(`/api/v2/locations/zip/${slug.match(ZIP_REGEX)[2]}`)
    } else if (DEFAULT_REGEX.test(slug)) {
        slug = slug.match(DEFAULT_REGEX)[1]
    }
    return this.get(`/api/v2/locations/${slug}`)
}

export function searchBaseSchool({ latitude, longitude, name, limit }) {
    return this.get("/api/search/schoolbase", {

        latitude,
        longitude,
        limit,
        name,

    })
}

export function getCities() {
    return this.get("/api/search/cities")
}

export function searchJobs({
    type,
    term,
    channel,
    location,
    distance,
    category,
    list,
    duration,
    graduation,
    upperSalary,
    lowerSalary,
    avgResponseTime,
    exclude_job_slug,
    business_slug,
    page,
    authorType,
    onlyPro,
    perPage,
    additionalQueries,
}) {
    return this.get(
        `/api/v2/search/${type}`,
        {
            term,
            channel,
            category,
            list,
            distance,
            latitude: location?.latitude,
            longitude: location?.longitude,
            duration,
            graduation,
            avg_response_time: avgResponseTime,
            exclude_job_slug,
            business_slug,
            lower_salary: lowerSalary,
            upper_salary: upperSalary,
            only_pro: onlyPro,
            page,
            per_page: perPage,
            author_type: authorType,
            ...additionalQueries,
        },
        {
            rawResponse: true,
        }
    )
}

export function searchJobsMeta({
    type,
    term,
    channel,
    location,
    distance,
    category,
    authorType,
}) {
    return this.get(`/api/v2/search/${type}/meta`, {
        term,
        channel,
        category,
        distance,
        latitude: location?.latitude,
        longitude: location?.longitude,
        author_type: authorType,
    })
}

export function searchJobsSuggest({ type, term, channel, location, distance }) {
    return this.get(
        `/api/v2/search/${type}/suggest`,
        {
            term,
            channel,
            distance,
            latitude: location?.latitude,
            longitude: location?.longitude,
        },
        {
            rawResponse: true,
        }
    )
}
