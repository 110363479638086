
import { mapBusinessResponseTime } from "@/helpers/mapping"
import { clock } from "@/assets/icons/"
import themeable from "@/mixins/themeable"

export default {
    name: "BusinessResponseTime",
    icons: { clock },
    mixins: [themeable],
    props: {
        /** Time of response in seconds */
        time: {
            type: Number,
            required: true,
        },
        /** Displays a text-only version  */
        minimal: {
            type: Boolean,
            default: false,
        },
        /** Uses theme from ThemeProvider */
        themed: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        timeHours() {
            return mapBusinessResponseTime(this.time)
        },
        style() {
            if (this.themed) return
            const oneHour = 60 * 60
            const tenMins = 10 * 60
            if (this.time < 12 * oneHour + tenMins) {
                return { color: "#00be4c" } // Green
            }
            return { color: "#ffb41d" } // yellow
        },
    },
}
