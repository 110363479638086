
import {
    mdiChevronRight,
    mdiChevronDown,
    mdiChevronLeft,
    mdiChevronUp,
} from "@mdi/js"

export default {
    name: "ViewAllButton",
    props: {
        color: {
            type: String,
            default: "black--text",
        },
        fontSize: {
            type: String,
            default: "text-body-1",
        },
        /** Direction of the chevron */
        chevron: {
            type: String,
            default: "right",
            validator: (val) =>
                ["right", "down", "left", "up", "none"].includes(val),
        },
    },
    computed: {
        chevronIcon() {
            switch (this.chevron) {
                case "down":
                    return mdiChevronDown
                case "left":
                    return mdiChevronLeft
                case "up":
                    return mdiChevronUp
                default:
                    return mdiChevronRight
            }
        },
    },
}
