
import { routeToBusinessSite } from "@/helpers/routingEinBlick"
export default {
    name: "BusinessSiteMap",
    components: {
        Map: () => import("@/components/map/LibreMap"),
    },
    emits: ["click"],
    props: {
        business: {
            type: Object,
            required: true,
        },
        businessSite: {
            type: Object,
            default: null,
        },
        jumpTo: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        center: null,
    }),
    computed: {
        mapMarkers() {
            let markers = []
            this.business.business_sites?.map((site) => {
                markers.push({
                    lat: site.address.lat,
                    lng: site.address.lng,
                    id: site.id,
                    site: site,
                    label: this.getLocationName(site),
                    src: this.$getImage(site.logo, 3).src,
                    active: this.isActive(site),
                })
            })
            return markers
        },
    },
    watch: {
        businessSite() {
            this.updateCenter()
        },
    },
    created() {
        this.updateCenter()
    },
    methods: {
        getLocationName(site) {
            const address = site.address
            return `${address.city}, ${address.street}`
        },
        isActive(site) {
            if (!site) return false
            if (!this.businessSite) return site.is_main
            return site.id === this.businessSite?.id
        },
        onMarkerClick(marker) {
            if (!marker?.site) return
            if (this.isActive(marker.site)) return
            this.$router.push(routeToBusinessSite(marker.site))
            this.$emit("click", marker.size)
        },
        updateCenter() {
            const location =
                this.businessSite?.primary_address ??
                this.business.primary_address
            this.center = [location.lat, location.lng]
        },
    },
}
