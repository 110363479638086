
import { mdiChevronRight } from "@mdi/js"

export default {
    name: "ButtonBlok",
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    icons: {
        mdiChevronRight,
    },
    computed: {
        rel() {
            if (this.blok.rel === "follow") return ""
            return this.blok.rel || null
        },
    },
}
