
const RETINA_SUPPORTED_SIZES = [
    "job",
    "bus",
    "job_detail",
    "200_fit",
    "400_fit",
]
const PLACEHOLDERABLE_SIZES = ["job", "job@2", "bus", "bus@2"]
export default {
    name: "Picture",
    props: {
        loading: {
            default: "lazy",
            type: String,
            validator: (val) => ["lazy", "eager"].includes(val),
        },
        alt: {
            required: true,
            type: String,
        },
        width: {
            default: null,
            type: Number,
        },
        height: {
            default: null,
            type: Number,
        },
        /**
         * image which will be used or null if a placeholder should be used
         */
        image: {
            type: Object,
            default: null,
        },
        /**
         * size of the image or placeholder which should be used
         * <format>: <width x height>, <fit_algorithm>, <retina_size_exists>
         * job:         370x412, fit, @2
         * job_detail:  800x1067, fit, @2
         * bus:         370x180, fit, @2
         * 0:           2000x2000, (max size)
         * 1:           1000x1000, (max size)
         * 2:           500x500, (max size)
         * 3:           200x200, (max size)
         * 200_fit:     200x200, fit, @2
         * 400_fit:     400x400, fit, @2
         * 800_fit:     800x800, fit
         */
        size: {
            required: true,
            type: String,
            validator: (val) =>
                [
                    "job",
                    "job_detail",
                    "bus",
                    "0",
                    "1",
                    "2",
                    "3",
                    "p",
                    "200_fit",
                    "400_fit",
                    "800_fit",
                ].includes(val),
        },
        /** Mobile src for breakpoints xs-sm */
        mobileSize: {
            default: null,
            type: String,
            validator: (val) =>
                [
                    "job",
                    "job_detail",
                    "bus",
                    "0",
                    "1",
                    "2",
                    "3",
                    "p",
                    "200_fit",
                    "400_fit",
                    "800_fit",
                ].includes(val),
        },
        /**
         * defines the object fit of the image
         */
        fit: {
            default: "cover",
            type: String,
            validator: (val) =>
                ["fill", "contain", "cover", "none", "scale-down"].includes(
                    val
                ),
        },
        draggable: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        styleAttribute() {
            return `object-fit: ${this.fit};`
        },
    },
    methods: {
        srcset(format, size) {
            const defaultSrc = this.imageSource(format, size)

            if (RETINA_SUPPORTED_SIZES.includes(size)) {
                let retinaSrc = null
                if (size === "200_fit")
                    retinaSrc = this.imageSource(format, "400_fit")
                else if (size === "400_fit")
                    retinaSrc = this.imageSource(format, "800_fit")
                else retinaSrc = this.imageSource(format, size + "@2")
                return `${defaultSrc} 1x, ${retinaSrc} 2x`
            } else {
                return `${defaultSrc} 1x`
            }
        },
        imageSource(format, size) {
            let fileSuffix = `${size}.${format}`
            if (this.image && !this.isEmpty(this.image)) {
                if (!this.image.file_path && !this.image.url) return this.image.local
                fileSuffix = "_" + fileSuffix
                if (this.image.is_private === true)
                    return this.image.urls[fileSuffix]
                else
                  if (this.image?.urls[fileSuffix]) {
                    return this.image.urls[fileSuffix]
                  }
                    return (
                        this.image.file_path + this.image.file_name + fileSuffix
                    )
            } else {
                if (!PLACEHOLDERABLE_SIZES.includes(size)) {
                    console.error(
                        `Unsupported placeholder! Please create a placeholder for the format ${size}`
                    )
                }
                return `/placeholders/${fileSuffix}`
            }
        },
        isEmpty(file) {
            return (
                file &&
                Object.keys(file).length === 0 &&
                Object.getPrototypeOf(file) === Object.prototype
            )
        },
    },
}
