import { render, staticRenderFns } from "./Page.vue?vue&type=template&id=2fada1e4&scoped=true&"
import script from "./Page.vue?vue&type=script&lang=js&"
export * from "./Page.vue?vue&type=script&lang=js&"
import style0 from "./Page.vue?vue&type=style&index=0&id=2fada1e4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fada1e4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/builds/oabat/einblick/web/src/components/oabatify/Icon.vue').default,TransitionScrollYReverse: require('/builds/oabat/einblick/web/src/components/oabatify/transition/TransitionScrollYReverse.vue').default})
