
import { mdiChevronRight } from "@mdi/js"

export default {
    name: "PartnerCardBlok",
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    icons: {
        mdiChevronRight,
    },
    computed: {
        cardBackground() {
            if (!this.blok.fullimage) return
            return this.blok.image.filename
        },
    },
}
