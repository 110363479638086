// import md5 from "js-md5"
import { getApi } from "@/plugins/api"
import {
    getLocationFromRoute,
    searchJobsMeta,
    searchJobs,
    searchJobsSuggest,
} from "@/services/search.service"
import { hashString } from "@/helpers/utils"
import { getChannelBySlug } from "@/services/channel.service"

const DEFAULT_CHANNEL_SLUG = "oabat"
const DEFAULT_DISTANCE = 30

export class Search {
    loading = false
    hasNextPage = true
    meta = null
    perPage = null
    data = []
    aggregations = {}
    numberOfActiveFilters = 0
    constructor({
        type,
        category,
        location,
        distance = DEFAULT_DISTANCE,
        term,
        channel = DEFAULT_CHANNEL_SLUG,
        duration,
        graduation,
        avgResponseTime,
        lowerSalary,
        upperSalary,
        list,
        subdomain,
        authorType,
        additionalQueries,
        perPage,
    }) {
        this.type = type
        this.channel = channel
        this.category = category
        this.location = location
        this.distance = distance ?? undefined
        this.term = term
        this.duration = duration
        this.graduation = graduation
        this.avgResponseTime = avgResponseTime
        this.lowerSalary = lowerSalary
        this.upperSalary = upperSalary
        this.list = list
        this.numberOfActiveFilters = 0
        this.subdomain = subdomain
        this.authorType = authorType
        this.error = false
        this.additionalQueries = additionalQueries
        this.perPage = perPage || undefined
        const json =
            JSON.stringify({
                channel,
                type,
                term,
                category,
                location,
                distance,
                list,
            }) || ""
        this.id = hashString(json)

    }

    setNumberOfActiveFilters(n) {
        this.numberOfActiveFilters = n
    }

    get isInitialSearch() {
        return this.numberOfActiveFilters === 0 && !this.term && !this.category
    }

    async execute() {
        const page = this.meta ? this.meta.current_page + 1 : 1
        if (
            page === 1 &&
            (this.type === "apprenticeship" || this.type === "traineeship")
        ) {
            await this.executeAggregations()
        }
        if (this.loading || !this.hasNextPage) return
        this.loading = true
        const query = {
            type: this.type,
            term: this.term,
            list: this.list,
            location: this.location,
            distance: this.distance,
            channel: this.channel?.slug ?? this.channel ?? DEFAULT_CHANNEL_SLUG,
            category: this.category,
            duration: this.duration,
            graduation: this.graduation,
            avgResponseTime: this.avgResponseTime,
            lowerSalary: this.lowerSalary,
            upperSalary: this.upperSalary,
            authorType: this.authorType,
            perPage: this.perPage,
            page,
            additionalQueries: this.additionalQueries,
        }
        try {
            const { meta, data } = await getApi().run(searchJobs, query).request
            this.meta = meta
            this.data = this.data.concat(data)
            this.hasNextPage = this.meta.current_page < this.meta.last_page
        } catch (exception) {
            console.error(exception)
            this.error = exception
        }

        this.loading = false
    }

    async executeAggregations() {
        try {
            this.aggregations = await getApi().run(searchJobsMeta, {
                type: this.type,
                term: this.term,
                location: this.location,
                distance: this.distance,
                channel:
                    this.channel?.slug ?? this.channel ?? DEFAULT_CHANNEL_SLUG,
                author_type: this.authorType,
                category: this.category,
            }).request
        } catch (exception) {
            console.error(exception)
            this.error = exception
        }
    }

    async executeSuggestions(term) {
        try {
            const suggestParams = {
                type: this.type,
                term,
                channel:
                    this.channel?.slug ?? this.channel ?? DEFAULT_CHANNEL_SLUG,
                location: this.location,
                distance: this.distance,
            }

            const { data, meta } = await getApi().run(
                searchJobsSuggest,
                suggestParams
            ).request
            return { data, meta }
        } catch (exception) {
            console.error(exception)
            this.error = exception
        }
    }

    setTerm(term) {
        this.resetSearch()
        this.term = term
    }

    setCategory(category) {
        this.resetSearch()
        this.category = category
    }

    setDuration(durationInMonths) {
        this.resetSearch()
        this.duration = durationInMonths
    }

    setGraduation(graduation) {
        this.resetSearch()
        this.graduation = graduation
    }

    setLowerSalary(lowerSalary) {
        this.resetSearch()
        this.lowerSalary = lowerSalary
    }

    setChannel(channel) {
        this.resetSearch()
        this.channel = channel
    }

    setResponseTime(time) {
        this.resetSearch()
        this.avgResponseTime = time
    }

    setDistance(distance) {
        this.resetSearch()
        this.distance = distance
    }

    resetSearch() {
        this.data = []
        this.aggregations = {}
        this.meta = null
        this.hasNextPage = true
    }

    setLocation(location) {
        this.resetSearch()
        if (
            location &&
            !location.slug &&
            location.latitude &&
            location.longitude
        ) {
            this.location = {
                slug: "nearby",
                name: "Umgebung",
                latitude: location.latitude,
                longitude: location.longitude,
            }
        } else this.location = location
    }

    getRoute() {
        let page = this.meta?.current_page
        if (page <= 1) {
            page = undefined
        }
        const route = {
            name: this.getRouteName(),
            params: {
                locationSlug: this.location?.slug ?? this.channel?.slug,
                slug: this.location?.slug ?? this.channel?.slug,
            },
            query: {
                page,
                duration: this.duration,
                graduation: this.graduation,
                lower_salary: this.lowerSalary,
                upper_salary: this.upperSalary,
                distance: this.distance,
                category: this.category,
                avg_response_time: this.avgResponseTime,
                latitude: this.location?.latitude,
                longitude: this.location?.longitude,
            },
        }
        if (
            this.location &&
            this.location.longitude &&
            this.location.latitude
        ) {
            route.query.longitude = this.location.longitude
            route.query.latitude = this.location.latitude
        }
        if (this.term) {
            route.query.term = this.term
        }
        return route
    }

    getRouteName() {
        /** Case for jobFair & region page */
        if (this.subdomain) {
            let type = this.type
            if (this.type === "post") type = "news"
            if (this.type === "apprenticeship") type = "ausbildung"
            if (this.type === "traineeship") type = "praktikum"
            if (this.type === "business_site") type = "unternehmen"
            return `${this.subdomain}-v2-slug-${type}`
        }

        const namePrefix =
            this.type === "apprenticeship" ? "ausbildung" : "praktikum"
        return `${namePrefix}-locationSlug`
    }

    /** Needs $api from nuxt-context to execute api requests */
    async setParamsFromRoute(route) {
        // if location contains a number, it is a location (not a channel)
        const locationSlug = route.params.locationSlug
        const isLocationRoute =
            /\d/.test(locationSlug) || locationSlug === "nearby"
        const searchDistance = isLocationRoute
            ? route.query?.distance || DEFAULT_DISTANCE
            : null
        if (route?.query) {
            this.term = route.query.term
            this.avgResponseTime = route.query.avg_response_time
            this.distance = searchDistance
            this.upperSalary = route.query.upper_salary
            this.lowerSalary = route.query.lower_salary
            this.graduation = route.query.graduation
            this.duration = route.query.duration
            this.category = route.query.category
        }
        if (isLocationRoute) {
            if (locationSlug === "nearby") {
                this.location = {
                    slug: "nearby",
                    name: "Umgebung",
                    latitude: route.query.latitude,
                    longitude: route.query.longitude,
                }
            } else {
                this.location = await getApi().run(getLocationFromRoute, route)
                    .request
            }
        } else {
            this.channel = await getApi().run(
                getChannelBySlug,
                route.params.locationSlug
            ).request
        }
    }
}
