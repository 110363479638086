
import { search_thick, location, close } from "@/assets/icons"
import {
    routeToBusinessSite,
    routeToBusinessMain,
} from "@/helpers/routingEinBlick"
import themeable from "@/mixins/themeable"
import { ThemeProvider } from "@/components/theme"

export default {
    name: "LocationModal",
    components: {
        ThemeProvider,
        BusinessSiteMap: () =>
            import("@/components/businesssite/BusinessSiteMap"),
    },
    mixins: [themeable],
    props: {
        /** Business to show sites for */
        business: {
            type: Object,
            required: true,
        },
        /** Currently selected businessSite */
        current: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        search: "",
    }),
    computed: {
        businessSites() {
            if (!this.search) return this.business.business_sites
            return this.business.business_sites.filter((site) =>
                this.getSiteName(site)
                    .toLowerCase()
                    .includes(this.search.toLowerCase().replace(/,/g, ""))
            )
        },
    },
    watch: {
        "$attrs.value": function (newVal) {
            if (newVal) this.search = ""
        },
    },
    methods: {
        getSiteName(site) {
            return `${site.address.city} ${site.address.street} ${site.address.postal_code}`
        },
        onSelect() {
            this.$emit("input", false)
        },
        isActive(site) {
            return site.id === this.current?.id
        },
    },
    icons: {
        search_thick,
        location,
        close,
    },
    routes: {
        routeToBusinessSite,
        routeToBusinessMain,
    },
}
