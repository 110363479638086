
import "isomorphic-fetch"
import Vue from "vue"
import {
    StoryblokVue,
    apiPlugin,
    useStoryblokApi,
    useStoryblokBridge,
} from "@storyblok/vue-2"

import upperFirst from "lodash/upperFirst"
import camelCase from "lodash/camelCase"

import getStructuredData from "@/components/storyblok/seo/structuredDataEvent"
import { createHead } from "@/helpers/head"
import { truncate } from "@/helpers"

Vue.use(StoryblokVue, {
    accessToken: "La8Cd4jKOewEdycWVcUMhgtt",
    use: [apiPlugin],
    apiOptions: {
        region: "eu",
    },
})
const requireComponent = require.context(
    "@/components/storyblok",
    true,
    /.*\.(vue)$/
)
requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName)

    const componentName = upperFirst(
        camelCase(
            fileName
                .split("./")
                .pop()
                .replace(/\.\w+$/, "")
        )
    )

    Vue.component(componentName, componentConfig.default || componentConfig)
})

export default {
    name: "StoryblokRoute",
    async asyncData({ route, error, $config }) {
        let pathString = route.path.slice(1).replace("/", "-")
        if (pathString === "-") pathString = "" /* index */
        const version =
            !$config.isProduction || route.query?._storyblok !== undefined
                ? "draft"
                : "published"
        const story = await useStoryblokApi()
            .get(`cdn/stories/${pathString || "index"}`, { version })
            .then(({ data }) => data.story)
            .catch(() => {
                error({
                    statusCode: 404,
                    message: "Seite nicht gefunden.",
                })
            })
        return { story }
    },
    mounted() {
        if (this.story?.id && this.$route.query?._storyblok !== undefined) {
            useStoryblokBridge(
                this.story.id,
                (evStory) => (this.story = evStory)
            )
        }
    },
    head() {
        const story = this.story
        if (!story?.content) return
        let { title, description, image } = story.content
        const event = story.content?.headContent.find(
            (el) => el.component === "seo-structuredDataEvent"
        )
        return createHead()
            .setTitle(title)
            .setDescription(truncate(description, 317))
            .setImage(image)
            .addScript(getStructuredData.call(this, event))
            .build()
    },
}
