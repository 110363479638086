function linkifyTextElement(textElement) {
    // since URLs can contain "@" it is easier to check e-mail pattern beforehand
    let emailPattern = /^\w([.-]?[\wöäüÖÄÜß])*@\w+([.-]?\w+)*(\.\w{2,})+$/
    if (emailPattern.test(textElement)) return textElement

    // http://, https://, ftp://
    let urlPattern =
        /\b(?:https?|ftp):\/\/[a-z0-9-+&@#/%?=~_|!:,.;]*[a-z0-9-+&@#/%=~_|]/gim

    // www. sans http:// or https://
    let pseudoUrlPattern = /(^|[^/])(www\.[\S]+(\b|$))/gim
    // links without www or http://
    let shallowUrlPattern = /^([a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+.*)$/gim

    return textElement
        .replace(urlPattern, '<a href="$&" target="_blank">$&</a>')
        .replace(pseudoUrlPattern, '<a href="http://$2" target="_blank">$2</a>')
        .replace(
            shallowUrlPattern,
            '<a href="https://$1" target="_blank">$1</a>'
        )
}
/**
 * Clean all html tags from the text to prevent it from breaking v-html directives.
 *
 *
 * @export
 * @param {string} text - message text
 * @param {boolean} allowLinks - parse all html links in the text and convert them to <a> tag
 * @return {string}
 */
export default function sanitizeText(text, allowLinks) {
    if (!text) return
    const sanitizedText = text.replace(/(<([^>]+)>)/gi, "") // remove any html tags
    if (allowLinks) {
        return sanitizedText
            .split(" ")
            .map((textElement) => linkifyTextElement(textElement))
            .join(" ")
    }

    return sanitizedText
}
