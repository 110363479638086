
import jobFeatures from "@/mixins/jobFeatures"
import { ContactPersonSection } from "@/components/business/contactperson"
import { chevron_down } from "@/assets/icons"
import {
    EVENT_APPLY_MODAL_APPLY_BUTTON_CLICK,
    EVENT_APPLY_MODAL_MESSAGE_BUTTON_CLICK,
} from "@/enums/googleAnalytics"

export default {
    name: "JobPopupCard",
    components: { ContactPersonSection },
    mixins: [jobFeatures],
    emits: ["close"],
    props: {
        job: {
            type: Object,
            required: true,
        },
    },
    icons: {
        chevron_down,
    },
    computed: {
        greetingText() {
            const business = this.job.business
            let displayName = business.name
            const cp = business?.public_contact_person
            if (cp?.firstname || cp?.lastname) {
                displayName = `${cp.firstname ?? ""} ${cp.lastname ?? ""}`
            }
            return `"Willkommen beim EinBlick in den Beruf ${this.job.name}. Ich bin ${displayName} und wenn du Fragen oder Interesse hast, dann kannst du dich bei mir melden."`
        },
        externalApplicationUrl() {
            if (!this.featureExternalApplication) return
            const url =
                this.job.external_application_url ?? this.job.external_website
            if (!url) return ""
            return url
        },
    },
    methods: {
        onApplyBtnClick() {
            this.$gtag.event(EVENT_APPLY_MODAL_APPLY_BUTTON_CLICK)
            this.$oabalytics.trackEvent("job-application", {
                is_external_available: !!this.externalApplicationUrl,
            })
            if (this.externalApplicationUrl) return
            this.$chatForm.open("ChatApplyForm", { job: this.job })
        },
        onChat() {
            this.$gtag.event(EVENT_APPLY_MODAL_MESSAGE_BUTTON_CLICK)
            this.$chatForm.open("ChatMessageForm")
        },
    },
}
