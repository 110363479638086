import {
    FEATURE_POWERED_BY,
    FEATURE_OWN_JOBS,
    FEATURE_OTHER_JOBS,
    FEATURE_EXTERNAL_APPLICATION,
    FEATURE_GOOGLE_JOBS,
    FEATURE_IFRAME,
    FEATURE_TEASER,
    FEATURE_THEME,
    FEATURE_TITLE_IMAGE,
} from "@/enums/jobFeatures"

export default {
    methods: {
        hasFeature(feature) {
            if (this.featuresDisabled) return false
            return this.featuresJob.features?.includes(feature) ?? false
        },
    },
    computed: {
        featuresJob() {
            const job = this.job instanceof Function ? this.job() : this.job
            return (job || this.displayedJob) ?? {}
        },
        featuresDisabled() {
            return Number(this.featuresJob.version) === 1
        },
        featureTeaser() {
            if (this.hasFeature(FEATURE_TEASER)) return true
            return this.hasFeature("job-teaser")
        },
        featurePoweredBy() {
            /** is edit page -> check for inverted slug-based feature */
            if (
                this.$route.name === "betrieb-job-erstellen" ||
                this.$route.name === "betrieb-job-slug-bearbeiten"
            ) {
                if (this.hasFeature(FEATURE_POWERED_BY)) return true
                if (this.hasFeature("job-hide-oabat")) return false
                return true
            }
            return this.hasFeature(FEATURE_POWERED_BY)
        },
        featureIFrame() {
            return this.hasFeature(FEATURE_IFRAME)
        },
        featureExternalApplication() {
            if (this.featuresDisabled) {
                return Boolean(
                    this.featuresJob.is_external_application_active ||
                        this.featuresJob.use_external_site
                )
            }
            if (this.hasFeature(FEATURE_EXTERNAL_APPLICATION)) return true
            return this.hasFeature("job-external-page")
        },
        featureOwnJobs() {
            if (this.featuresDisabled)
                return Boolean(this.featuresJob.shown_own_jobs)
            if (this.hasFeature(FEATURE_OWN_JOBS)) return true
            return this.hasFeature("job-no-ads")
        },
        featureOtherJobs() {
            if (this.featuresDisabled) return !this.featuresJob.shown_own_jobs
            if (this.hasFeature(FEATURE_OTHER_JOBS)) return true
            return !this.hasFeature("job-no-ads")
        },
        featureGoogleJobs() {
            if (this.hasFeature(FEATURE_GOOGLE_JOBS)) return true
            return !this.hasFeature("job-google-jobs")
        },
        featureTheme() {
            if (this.featuresDisabled) return false
            if (this.hasFeature(FEATURE_THEME)) return true
            return false
        },
        featureTitleImage() {
            if (this.featuresDisabled) return false
            if (this.hasFeature(FEATURE_TITLE_IMAGE)) return true
        },
    },
}
