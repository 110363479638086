
import {BusinessLogo} from "@/components/business"
export default {
    name: "BusinessHeader",
    components: {
        BusinessLogo,
    },
    props: {
        business: {
            type: Object,
            required: true,
        },
        to: {
            type: Object,
            default: null,
        },
    },
}
