export function getJobBySlug(slug) {
    return this.get(`/api/v2/jobs/${slug}`)
}

export function getJobsBySiteSlugCombined(businessSlug, businessSiteCombinedSlug) {
    return this.get("/api/v2/jobs", {
        business: businessSlug,
        business_site_combined: businessSiteCombinedSlug
    })
}
