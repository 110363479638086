
export default {
    name: "LightTextBlok",
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    computed: {
        classes() {
            return [
                this.blok.bottomPaddingMobile,
                this.blok.bottomPaddingDesktop,
                this.blok.fontSize,
                this.blok.textAlignment,
                this.blok.textColor,
            ]
        },
        tag() {
            return this.blok.htmlTag ?? "p"
        },
        hasDesktopText() {
            if (this.blok.mobileOnly)
                return true /* Applies hiding classes, but doesnt render desktop text */
            return !!this.blok.desktopText
        },
        desktopHideHelpers() {
            const displayValue =
                this.blok.htmlTag === "span" ? "inline" : "block"
            return `d-none d-md-${displayValue}`
        },
    },
    methods: {
        formatText(text) {
            if (!text) return
            return text
                .replace(/\*([^*]+?)\*/g, '<span class="font-weight-bold">$1</span>')
                .replace(/_([^*]+?)_/g, '<span class="font-weight-light">$1</span>')
        },
    },
}
