
export default {
    name: "ContactPersonAvatar",
    props: {
        /** business object */
        business: {
            type: Object,
            required: true,
        },
        size: {
            type: Number,
            default: 40,
        }
    },
    computed: {
        contactImage() {
            return this.contactPerson?.profile_picture ?? this.business.logo
        },
        contactPerson() {
            return this.business?.public_contact_person
        },
        imgAlt() {
            let alt = `Ansprechpartner ${this.business.name}`
            if (this.contactPerson?.firstname || this.contactPerson?.lastname) {
                alt += `: ${this.contactPerson.firstname ?? ""} ${
                    this.contactPerson.lastname ?? ""
                }`
            }
            return alt
        },
    },
}
