
import { OABALYTICS_APPRENTICESHIP_APPLY_BTN } from "@/enums/oabalytics"
import { EVENT_APPLY_MODAL_APPLY_BUTTON_CLICK } from "@/enums/googleAnalytics"

export default {
    name: "JobApprenticeshipDatePicker",
    props: {
        job: {
            type: Object,
            required: true,
        },
        forNextYears: {
            type: Number,
            default: 2,
        },
        themeBackground: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        prefix() {
            return new Intl.DateTimeFormat("de-DE", {
                day: "2-digit",
                month: "long",
            }).format(this.dateOfStart)
        },
        dateOfStart() {
            if (!this.job.date_of_start) {
                return new Date(new Date().getFullYear(), 8, 1)
            }
            return new Date(this.job.date_of_start)
        },
        currentYear() {
            if (this.job.date_application_deadline) {
                const application_deadline = new Date(
                    this.job.date_application_deadline
                )
                if (application_deadline < new Date()) {
                    return application_deadline.getFullYear() + 1
                }
            }
            return this.dateOfStart.getFullYear()
        },
        buttonYears() {
            return [...Array(this.forNextYears + 1).keys()]
        },
    },
    methods: {
        onApply(year) {
            this.$gtag.event(EVENT_APPLY_MODAL_APPLY_BUTTON_CLICK)
            this.$oabalytics.trackEvent(
                OABALYTICS_APPRENTICESHIP_APPLY_BTN,
                year || "custom"
            )
            let prefillYear = ""
            if (year) {
                prefillYear = `${this.prefix} ${year}`
            }
            this.$chatForm.open("ChatApplyForm", { job: this.job , apprenticeshipYear: prefillYear})
        },
    },
}
